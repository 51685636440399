import { type LoadableSitecoreContextRouteProps } from "~/foundation/Multisite";
import { SitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Loadable } from "~/foundation/ReactLoadable";
import { ComponentLoader } from "~/foundation/Components/Loading/ComponentLoader";
import { Route, Routes } from "react-router-dom";

type ProjectImportProps = typeof import("./Project"); // eslint-disable-line

export const LoadableSitecoreContextRoute = Loadable<ProjectImportProps, LoadableSitecoreContextRouteProps>({
	loader: () => import(/* webpackChunkName: "AramcoWorld_Project" */'./Project'),
	render: ({ Layout, componentFactory }, { Router, path, JssRoute, ssrState, siteName, project }) => {
		return (
			<SitecoreContext componentFactory={componentFactory} layoutData={ssrState}>
				<Router location={path} context={{}}>
					<Routes>
						<Route path="*" element={<JssRoute isSSR={!!ssrState} siteName={siteName} projectLayer={project} LayoutComponent={Layout} />} />
					</Routes>
				</Router>
			</SitecoreContext>
		);
	},
	loading: (props: { error?: Error | unknown, retry?: () => void }) => <ComponentLoader componentName="SitecoreContext" {...props} />,
	modules: ['AramcoWorld_componentFactory'],
	moduleId: "AramcoWorld_componentFactory"
});