import { RestDictionaryService } from "@sitecore-jss/sitecore-jss-react";
import config from "~/temp/config";

export class DictionaryServiceFactory {
	create(siteName: string) {
		return new RestDictionaryService({
			apiHost: config.sitecoreApiHost,
			apiKey: config.sitecoreApiKey,
			siteName,
		});
	}
}

export const dictionaryServiceFactory = new DictionaryServiceFactory();
