/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import React from "react";
import { redirect } from "react-router-dom";
import i18n from "i18next";
import axios, { type CancelTokenSource } from "axios";
import { isEditorActive, type LayoutServiceData, withSitecoreContext, type ComponentFactory } from "@sitecore-jss/sitecore-jss-react";
import config from "~/temp/config";
import { layoutServiceFactory } from "./layout-service-factory";
import { type SitecoreContextValue } from "./.";
import { type LayoutServiceRedirect } from "./generated-types";
import { type LayoutProps } from "~/foundation/Multisite";

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

export type RouterHandlerState = {
	redirectTo: string | null;
}

export type RouterHandlerProps = {
	isSSR: boolean;
	route: string;
	language: string | undefined;
	sitecoreContext: SitecoreContextValue & { error?: unknown };
	updateSitecoreContext: (value: any) => void;
	siteName: string;
	url: string;
	projectLayer: string;
	componentFactory?: ComponentFactory;
	LayoutComponent: React.ComponentType<LayoutProps>;
}

class RouteHandler extends React.Component<RouterHandlerProps, RouterHandlerState> {

	private currentCancelTokenSource: CancelTokenSource | undefined;

	constructor(props: RouterHandlerProps) {
		super(props);

		this.state = {
			redirectTo: null
		};

		// tell i18next to sync its current language with the route language
		this.updateLanguage();
	}

	componentDidMount() {
		// If we are not using SSR we have to load layout data
		if (!this.props.isSSR) {
			this.updateLayoutData();
		}
	}

	/**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
	updateLayoutData(cancelTokenSource?: CancelTokenSource): Promise<void> {
		let sitecoreRoutePath = this.props.route || "/";
		if (!sitecoreRoutePath.startsWith("/")) {
			sitecoreRoutePath = `/${sitecoreRoutePath}`;
		}

		const language = this.getLanguage();

		// instantiate the dictionary service.
		const layoutServiceInstance = layoutServiceFactory.create(this.props.siteName, cancelTokenSource);

		return new Promise((resolve) => {
			// get the route data for the new route
			layoutServiceInstance.fetchLayoutData(sitecoreRoutePath, language)
				.then((routeData: LayoutServiceData & LayoutServiceRedirect) => {
					if (!routeData.sitecore && routeData.redirect) {

						this.setState({
							redirectTo: routeData.redirect.redirectUrl
						});

						resolve(undefined);
						return;
					}

					this.props.updateSitecoreContext(routeData);

					if (!window.location.hash) {
						window.scrollTo(0, 0);
					}

					resolve(undefined);
				})
				.catch(error => {
					if (error?.message === "cancelled") {
						resolve(undefined);
						return;
					}

					const newContext = { ...this.props.sitecoreContext, error };
					this.props.updateSitecoreContext(newContext);
					window.scrollTo(0, 0);
					resolve(undefined);
				});
		})
	}

	getLanguage() {
		return (
			this.props.language ||
			this.props.sitecoreContext?.language ||
			config.defaultLanguage
		);
	}

	/**
   * Updates the current app language to match the route data.
   */
	updateLanguage() {
		const newLanguage = this.getLanguage();

		if (i18n.language !== newLanguage) {
			i18n.changeLanguage(newLanguage);
		}
	}

	componentDidUpdate(previousProps: RouterHandlerProps) {
		const existingRoute = previousProps.url;
		const newRoute = this.props.url;

		// don't change state (refetch route data) if the route has not changed
		if (existingRoute === newRoute) {
			return;
		}

		// if in Sitecore editor - force reload instead of route data update
		// avoids confusing Sitecore's editing JS
		if (isEditorActive()) {
			window.location.assign(newRoute);
			return;
		}

		// if a fetch is already running, cancel it
		if (this.currentCancelTokenSource) {
			this.currentCancelTokenSource.cancel("cancelled");
			if (window.location.pathname.toLowerCase() === newRoute.toLowerCase()) {
				return;
			}
		}

		const cancelTokenSource = axios.CancelToken.source();
		this.currentCancelTokenSource = cancelTokenSource;

		this.updateLanguage();
		this.updateLayoutData(cancelTokenSource)
			.then(() => {
				this.currentCancelTokenSource = undefined;
			});
	}

	render() {
		const { redirectTo } = this.state;

		if (redirectTo) {
			if (typeof window !== "undefined") {
				window.location.href = redirectTo;
			}
			redirect(redirectTo)
			return <></>;
		}

		const layoutData = this.props.sitecoreContext;
		const isRtl = layoutData?.custom?.settings?.isRtl || false;
		const Layout = this.props.LayoutComponent;

		return (
			<Layout sitecoreContext={layoutData} isRtl={isRtl} />
		);
	}
}

export default withSitecoreContext({ updatable: true })(RouteHandler);
